import { encryptData } from './sm-Crypto';

export function getSignSecret() {
  const secret = encryptData(import.meta.env?.VITE_SIGN_SECRET);
  return secret;
}
export function getAppTypeSecret() {
  const secret = encryptData(import.meta.env?.VITE_APPTYPE_SECRET);
  return secret;
}
