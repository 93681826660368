import { HmacSHA256, enc } from 'crypto-js';
import { getSignSecret, getAppTypeSecret, decryptData } from '@/utils';

~(() => {

  const a: Window & typeof globalThis = window;
  const originalFetch = a.fetch;
  const requestMap: Map<string, { url: string; options: RequestInit }> = new Map();
  const responseMap: Map<string, { response?: Response; error?: any }> = new Map();
  const nowFunc = a.Date.now;
  const randomFunc = a.Math.random;
  const floorFunc = a.Math.floor;
  const userIdMap: Record<string, number> = {};
  const get256Hash = (str: string, secret: string) => {
    return HmacSHA256(str, secret).toString(enc.Hex);
  }

  const getKey = (): string => {
    return nowFunc().toString(16) + randomFunc().toString(16).substring(2);
  };

  const signProject = async () => {
    const processRequest = async (key: string, requestData: { url: string; options: RequestInit }) => {
      const { url, options } = requestData;
      const time = nowFunc();
      const input = url.split("?input=")[1] || options?.body?.split('"json":')[1];
      if (!input) return;
      const uAgent = await useSystemStore().app?.build?.toLowerCase();
      const secret = {
        sign: decryptData(getSignSecret()),
        appType: decryptData(getAppTypeSecret()),
      };
      const hash: string = get256Hash(`${input.replace(/%/g, '')}${time}`, secret.sign);
      const hashAgent: string = get256Hash(`${uAgent}`, secret.appType);
      const random = floorFunc(randomFunc() * 7) + 1;
      const timeWarp = time + random;
      const timeWarpStr = timeWarp.toString(16);
      const sign: string = `${hash}:${hashAgent}:${timeWarpStr}${random.toString(16)}`;
      console.log(time, 'time', `${input.replace(/%/g, '')}${time}`, 'input')
      options.headers = options.headers || {};
      (options.headers as Record<string, string>)["sign"] = sign;

      try {
        console.log(`Sending request to ${url} with sign: ${sign}`);
        const response = await originalFetch(url, options);
        responseMap.set(key, { response });
      } catch (error) {
        console.error(`Error fetching ${url}:`, error);
        responseMap.set(key, { error });
      } finally {
        requestMap.delete(key);
      }
    };

    const customFetch = async (url: string, options: RequestInit): Promise<Response> => {
      const key = getKey();
      const requestData = { url, options };
      requestMap.set(key, requestData);
      await processRequest(key, requestData);
      const responseData = responseMap.get(key);
      responseMap.delete(key);
      if (responseData?.error) {
        throw responseData.error;
      }
      console.log(responseData, responseData?.response, 'responseData?.response')
      return responseData?.response;
    };

    const fetch = new a.Proxy(originalFetch, {
      apply: (target, thisArg, argumentsList: [RequestInfo | URL, RequestInit?]) => {
        const [url, options] = argumentsList;
        if (typeof url === 'string' && !url.includes("/trpc/")) {
          return target.apply(thisArg, argumentsList);
        }
        console.log("Custom fetch proxy set up successfully.");
        return customFetch(url as string, options || {});
      }
    });
    a.fetch = fetch;
  }

  Promise.resolve().then(signProject); // 异步注册sign
  console.log("Custom fetch proxy set up successfully.");
})();
